"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/account-manage-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _typeof = require("/var/lib/jenkins/workspace/account-manage-admin/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
require("/var/lib/jenkins/workspace/account-manage-admin/node_modules/core-js/modules/es6.array.iterator.js");
require("/var/lib/jenkins/workspace/account-manage-admin/node_modules/core-js/modules/es6.promise.js");
require("/var/lib/jenkins/workspace/account-manage-admin/node_modules/core-js/modules/es6.object.assign.js");
require("/var/lib/jenkins/workspace/account-manage-admin/node_modules/core-js/modules/es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _dayjs = _interopRequireDefault(require("dayjs"));
require("normalize.css/normalize.css");
var _elementUi = _interopRequireDefault(require("element-ui"));
require("./assets/styles/element-variables.scss");
require("@/assets/styles/index.scss");
require("@/assets/styles/common.scss");
var _App = _interopRequireDefault(require("./App"));
var _store = _interopRequireDefault(require("./store"));
var _router = _interopRequireDefault(require("./router"));
var _permission = _interopRequireDefault(require("./directive/permission"));
var filters = _interopRequireWildcard(require("@/utils/filters"));
require("./assets/icons");
require("./permission");
var _common2 = require("@/utils/common");
var _Pagination = _interopRequireDefault(require("@/components/Pagination"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
// import Cookies from 'js-cookie'

// a modern alternative to CSS resets

// global css

// icon
// permission control

// 全局方法挂载
_vue.default.prototype.parseTime = _common2.parseTime;
_vue.default.prototype.resetForm = _common2.resetForm;
_vue.default.prototype.addDateRange = _common2.addDateRange;
_vue.default.prototype.selectDictLabel = _common2.selectDictLabel;
_vue.default.prototype.download = _common2.download;
_vue.default.prototype.dayjs = _dayjs.default;
_vue.default.prototype.$currentTime = filters;
_vue.default.prototype.msgSuccess = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: 'success'
  });
};
_vue.default.prototype.msgError = function (msg) {
  this.$message({
    showClose: true,
    message: msg,
    type: 'error'
  });
};
_vue.default.prototype.msgInfo = function (msg) {
  this.$message.info(msg);
};

// 全局组件挂载
_vue.default.component(_Pagination.default);
_vue.default.use(_permission.default);

/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */

_vue.default.use(_elementUi.default, {
  size: 'small' // set element-ui default size
});

_vue.default.config.productionTip = false;
_vue.default.config.devtools = true;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});