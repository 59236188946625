"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/account-manage-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/account-manage-admin/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _auth = require("@/utils/auth");
var _login = _interopRequireDefault(require("@/services/modules/login"));
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
var user = {
  state: {
    token: (0, _auth.getToken)(),
    name: '',
    avatar: '',
    roles: [],
    permissions: []
  },
  mutations: {
    SET_TOKEN: function SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_ID: function SET_ID(state, expires_in) {
      state.expires_in = expires_in;
    },
    SET_NAME: function SET_NAME(state, name) {
      state.name = name;
    },
    SET_AVATAR: function SET_AVATAR(state, avatar) {
      state.avatar = avatar;
    },
    SET_ROLES: function SET_ROLES(state, roles) {
      state.roles = roles;
    },
    SET_PERMISSIONS: function SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    }
  },
  actions: {
    // 登录
    Login: function Login(_ref, userInfo) {
      var commit = _ref.commit;
      // 删除code与uuid
      // const code = userInfo.code
      // const uuid = userInfo.uuid
      return new Promise(function (resolve, reject) {
        // 二次修改 直接抛出token，不进行接口请求
        // let myToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIzIiwianRpIjoiYTBjZDlmOGUxNjRkMDg1MWUxZmI2MzgyOTkwMjliY2QzYjkyYzYzMTM4OTQzZWI1M2VmZGU1ZjdkMDg3NmQ5NWUwZTVhNTc4NjA2YTgyMzAiLCJpYXQiOjE1ODU4MDY5NjUsIm5iZiI6MTU4NTgwNjk2NSwiZXhwIjoxNjE3MzQyOTY1LCJzdWIiOiIxIiwic2NvcGVzIjpbImJhc2ljLWFkbWluIl19.byMIaFpStYNK-A6M4WH08usLRy3YFQze-y7Epregs5dK1KJ23l_mlciLaXg7J6oQtd8PVuWXbkfQ2fD5Kc4SofcJIaMnqPd8G9YyH158EorPtOA2tExUjiF0XswNlEuorvQZvKhmt83q3yOFhB5vPwrLTb4nGkz-Jb4JCWzoEMbJFexOjYEbiELVSb6my1Og0hI65mKufFvs2ju-Y0f5lEZyq3tJeaQAN6GE9RLg1wqQXy89JnkN1buqdj6E4CoxLjGDG5LAXHbCNAN7idmz72lV1-Idz7WjSNqWG7PWsal3_RU5lB6NtmsjSsyl_DeVpYnqGhvUMmk0llFg7ATUPPTPA2q8cszYBjzZ-8HpDTWOS9yuhhMOAE48UAltU0aTGLjUWmge69LyqYECM7F-Z64Bl-ooVPBBqy_dcZcJh9WM0JfwgSa_Q4rIHfHBCYyJAU9bJcaDsgyrvkZJV4FDnRwfhUdhImy5jW4FKb6bmPPIzR_Ikx0pwjRx6q739bHOe0Kyp_ZxN0OttqiyUEn-cGF938yZuAY_7Zc4r8CGjpAXLo6c7N2BxS9a9ZZ2bwSm1TrHQMAKar_5fgyNkT1yl8WVXl3Y8fH5O6WjXhHZZu7U88sfaIwa_nGZEYk2ciChFlVnOpF0oIQNk2sWHcKE4OzQEHpt1noW-mB8b6_vJ88"
        // setToken(myToken)
        // commit('SET_TOKEN', myToken)
        // resolve()
        // return
        // 设定token
        // login(username, password).then(res => {
        //   const token = res.data.access_token
        //   setToken(token)
        //   commit('SET_TOKEN', token)
        //   commit('SET_ID', res.data.expires_in)
        //   resolve()
        // }).catch(error => {
        //   console.log("我是错误的")
        //   reject(error)
        // })

        _login.default.login(_objectSpread(_objectSpread({}, userInfo), {}, {
          name: userInfo.username
        })).then(function (res) {
          if (res.code === 0) {
            localStorage.setItem('userName', userInfo.username);
            localStorage.setItem('password', userInfo.password);
            var token = res.data.access_token;
            console.log(res.data);
            // debugger
            var websites = JSON.stringify(res.data.websites);
            localStorage.setItem('websites', websites);
            localStorage.setItem('token', token);
            (0, _auth.setToken)(token);
            commit('SET_TOKEN', token);
            var roleIds = [];
            // roleIds===========
            var roleIds_2 = res.data.auth_group_list;
            roleIds_2.forEach(function (item) {
              roleIds.push(item.id);
            });
            var roleIds_3 = roleIds.join(',');
            localStorage.setItem('roleIds', roleIds_3);
            resolve(res);
          } else {
            resolve(res);
          }
        }).catch(function (e) {
          reject(e);
        });
      });
    },
    // 获取用户信息
    GetInfo: function GetInfo(_ref2) {
      var commit = _ref2.commit,
        state = _ref2.state;
      return new Promise(function (resolve, reject) {
        // 二次修改 抛出用户信息，不进行请求
        var res = {
          code: 0,
          data: {
            username: '',
            role_id: 0
          }
        };
        var _res = {
          'msg': '操作成功',
          'code': 200,
          'permissions': ['*:*:*'],
          'roles': ['admin'],
          'user': {
            'searchValue': null,
            'createBy': 'admin',
            'createTime': '2018-03-16 11:33:00',
            'updateBy': null,
            'updateTime': null,
            'remark': '管理员',
            'dataScope': null,
            'params': {},
            'userId': 1,
            'deptId': 103,
            'userName': 'admin',
            'nickName': 'dancen',
            'email': 'dancen@ecoph.com',
            'phonenumber': '15888888888',
            'sex': '1',
            'avatar': '',
            'password': '$2a$10$7JB720yubVSZvUI0rEqK/.VqGOZTH.ulu33dHOiBE8ByOhJIrdAu2',
            'salt': null,
            'status': '0',
            'delFlag': '0',
            'loginIp': '127.0.0.1',
            'loginDate': '2018-03-16T11:33:00.000+0800',
            'dept': {
              'searchValue': null,
              'createBy': null,
              'createTime': null,
              'updateBy': null,
              'updateTime': null,
              'remark': null,
              'dataScope': null,
              'params': {},
              'deptId': 103,
              'parentId': 101,
              'ancestors': null,
              'deptName': '研发部门',
              'orderNum': '1',
              'leader': 'dancen',
              'phone': null,
              'email': null,
              'status': '0',
              'delFlag': null,
              'parentName': null,
              'children': []
            },
            'roles': [{
              'searchValue': null,
              'createBy': null,
              'createTime': null,
              'updateBy': null,
              'updateTime': null,
              'remark': null,
              'dataScope': '1',
              'params': {},
              'roleId': 1,
              'roleName': '管理员',
              'roleKey': 'admin',
              'roleSort': '1',
              'status': '0',
              'delFlag': null,
              'flag': false,
              'menuIds': null,
              'deptIds': null,
              'admin': true
            }],
            'roleIds': null,
            'postIds': null,
            'admin': true
          }
        };
        // const user = _res.user
        if (_res.roles && _res.roles.length > 0) {
          // 验证返回的roles是否是一个非空数组
          commit('SET_ROLES', [res.data.role_id]);
          commit('SET_PERMISSIONS', _res.permissions);
        } else {
          commit('SET_ROLES', ['ROLE_DEFAULT']);
        }
        commit('SET_NAME', res.data.username);
        var avatar = require('@/assets/image/profile.jpg');
        commit('SET_AVATAR', avatar);
        resolve(res);

        // LoginService.getInfo().then(res => {
        //   const {code,data} = res;
        //   if (!code) {

        //     let _res = {
        //       "msg": "操作成功",
        //       "code": 200,
        //       "permissions": [
        //         "*:*:*"
        //       ],
        //       "roles": [
        //         "admin"
        //       ],
        //       "user": {
        //         "searchValue": null,
        //         "createBy": "admin",
        //         "createTime": "2018-03-16 11:33:00",
        //         "updateBy": null,
        //         "updateTime": null,
        //         "remark": "管理员",
        //         "dataScope": null,
        //         "params": {},
        //         "userId": 1,
        //         "deptId": 103,
        //         "userName": "admin",
        //         "nickName": "dancen",
        //         "email": "dancen@ecoph.com",
        //         "phonenumber": "15888888888",
        //         "sex": "1",
        //         "avatar": "",
        //         "password": "$2a$10$7JB720yubVSZvUI0rEqK/.VqGOZTH.ulu33dHOiBE8ByOhJIrdAu2",
        //         "salt": null,
        //         "status": "0",
        //         "delFlag": "0",
        //         "loginIp": "127.0.0.1",
        //         "loginDate": "2018-03-16T11:33:00.000+0800",
        //         "dept": {
        //           "searchValue": null,
        //           "createBy": null,
        //           "createTime": null,
        //           "updateBy": null,
        //           "updateTime": null,
        //           "remark": null,
        //           "dataScope": null,
        //           "params": {},
        //           "deptId": 103,
        //           "parentId": 101,
        //           "ancestors": null,
        //           "deptName": "研发部门",
        //           "orderNum": "1",
        //           "leader": "dancen",
        //           "phone": null,
        //           "email": null,
        //           "status": "0",
        //           "delFlag": null,
        //           "parentName": null,
        //           "children": []
        //         },
        //         "roles": [
        //           {
        //             "searchValue": null,
        //             "createBy": null,
        //             "createTime": null,
        //             "updateBy": null,
        //             "updateTime": null,
        //             "remark": null,
        //             "dataScope": "1",
        //             "params": {},
        //             "roleId": 1,
        //             "roleName": "管理员",
        //             "roleKey": "admin",
        //             "roleSort": "1",
        //             "status": "0",
        //             "delFlag": null,
        //             "flag": false,
        //             "menuIds": null,
        //             "deptIds": null,
        //             "admin": true
        //           }
        //         ],
        //         "roleIds": null,
        //         "postIds": null,
        //         "admin": true
        //       }
        //     }
        //     const user = _res.user
        //     if (_res.roles && _res.roles.length > 0) { // 验证返回的roles是否是一个非空数组
        //       commit('SET_ROLES', [res.data.role_id])
        //       commit('SET_PERMISSIONS', _res.permissions)
        //     } else {
        //       commit('SET_ROLES', ['ROLE_DEFAULT'])
        //     }
        //     commit('SET_NAME', res.data.username)
        //     const avatar = require("@/assets/image/profile.jpg")
        //     commit('SET_AVATAR', avatar)
        //     resolve(res)
        //   } else {
        //     reject({})
        //   }

        // }).catch(error => {
        //   reject(error)
        // })
      });
    },
    // 退出系统
    LogOut: function LogOut(_ref3) {
      var commit = _ref3.commit,
        state = _ref3.state;
      return new Promise(function (resolve, reject) {
        // commit('SET_TOKEN', '')
        // commit('SET_ROLES', [])
        // commit('SET_PERMISSIONS', [])
        // removeToken()
        // resolve()
        localStorage.removeItem('token');
        commit('SET_TOKEN', '');
        commit('SET_ROLES', []);
        commit('SET_PERMISSIONS', []);
        (0, _auth.removeToken)();
        resolve();
        return;
        // logout(state.token).then(() => {
        //   commit('SET_TOKEN', '')
        //   commit('SET_ROLES', [])
        //   commit('SET_PERMISSIONS', [])
        //   removeToken()
        //   resolve()
        // }).catch(error => {
        //   reject(error)
        // })
      });
    },
    // 前端 登出
    FedLogOut: function FedLogOut(_ref4) {
      var commit = _ref4.commit;
      return new Promise(function (resolve) {
        commit('SET_TOKEN', '');
        (0, _auth.removeToken)();
        resolve();
      });
    }
  }
};
var _default = user;
exports.default = _default;