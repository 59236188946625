"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/account-manage-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _authority = _interopRequireDefault(require("@services/authority"));
//
//
//
//
//
//

// import PanelGroup from './dashboard/PanelGroup'
// import LineChart from './dashboard/LineChart'
// import RaddarChart from './dashboard/RaddarChart'
// import PieChart from './dashboard/PieChart'
// import BarChart from './dashboard/BarChart'

var lineChartData = {
  newVisitis: {
    expectedData: [100, 120, 161, 134, 105, 160, 165],
    actualData: [120, 82, 91, 154, 162, 140, 145]
  },
  messages: {
    expectedData: [200, 192, 120, 144, 160, 130, 140],
    actualData: [180, 160, 151, 106, 145, 150, 130]
  },
  purchases: {
    expectedData: [80, 100, 121, 104, 105, 90, 100],
    actualData: [120, 90, 100, 138, 142, 130, 130]
  },
  shoppings: {
    expectedData: [130, 140, 141, 142, 145, 150, 160],
    actualData: [120, 82, 91, 154, 162, 140, 130]
  }
};
var _default = {
  name: 'Index',
  components: {
    // PanelGroup,
    // LineChart,
    // RaddarChart,
    // PieChart,
    // BarChart
  },
  data: function data() {
    return {
      lineChartData: lineChartData.newVisitis
    };
  },
  created: function created() {
    this.judge();
  },
  methods: {
    handleSetLineChartData: function handleSetLineChartData(type) {
      this.lineChartData = lineChartData[type];
    },
    judge: function judge() {
      _authority.default.getList({
        pid: 1
      }).then(function () {}).catch();
    }
  }
};
exports.default = _default;