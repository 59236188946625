"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.currencyFloat = currencyFloat;
exports.dateUTCFormat = dateUTCFormat;
exports.filterOrderStatus = filterOrderStatus;
exports.getCurrentDate = getCurrentDate;
exports.orderStatusFilter = orderStatusFilter;
exports.orderStatusFilter2 = orderStatusFilter2;
exports.orderTypeFilter = orderTypeFilter;
var _dateFns = require("date-fns");
/**
 * 日期格式
 * @param {Date} value
 * @param {string} fmt
 */
function dateUTCFormat(value) {
  var fmt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'yyyy-MM-dd HH:mm:ss';
  // const nowDate = subHours(new Date(value), 8)
  return value ? (0, _dateFns.format)(new Date(value), fmt) : '-';
}
/**
 *获取当前日期
 * @param {Date} value
 * @param {string} fmt
 */
function getCurrentDate() {
  var nowDate = new Date();
  var year = nowDate.getFullYear();
  var month = nowDate.getMonth() + 1 < 10 ? '0' + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
  var day = nowDate.getDate() < 10 ? '0' + nowDate.getDate() : nowDate.getDate();
  var dateStr = year + '-' + month + '-' + day;
  return dateStr;
}

/**
 * 保留小数
 * @param {*} value
 * @param {*} digits
 */
function currencyFloat(value) {
  var digits = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  var pow = Math.pow(10, digits);
  var num = (Math.floor(value * pow) / pow).toFixed(digits);
  return +value ? num : 0 .toFixed(digits);
}

/**
 * 订单状态
 * @param {Number} value
 */
function orderStatusFilter(value) {
  var data = {
    1: '确认订单',
    2: '确认支付',
    3: '已取消',
    4: '已超时',
    5: '已确认',
    6: '交易失败',
    7: '申诉中',
    8: '已支付',
    10: '已完成',
    11: '已拒绝'
  };
  return data[value];
}

/**
 * 订单状态
 * @param {Number} value
 */
function orderStatusFilter2(value) {
  var data = {
    1: '未支付',
    2: '已支付',
    3: '已取消',
    4: '已超时',
    5: '已确认',
    6: '交易失败',
    7: '申诉中',
    8: '已支付',
    10: '已完成',
    11: '已拒绝'
  };
  return data[value];
}
function filterOrderStatus(value) {
  var data = {
    0: '成功',
    1: '等支付',
    2: '失效',
    3: '确认中'
  };
  return data[value];
}
function orderTypeFilter(v) {
  var data = {
    1: '买币',
    2: '卖币'
  };
  return data[v];
}