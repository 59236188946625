"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/account-manage-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
var _defineProperty2 = _interopRequireDefault(require("/var/lib/jenkins/workspace/account-manage-admin/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
var _router = _interopRequireDefault(require("./router"));
var _store = _interopRequireDefault(require("./store"));
var _elementUi = require("element-ui");
var _nprogress = _interopRequireDefault(require("nprogress"));
require("nprogress/nprogress.css");
var _auth = require("@/utils/auth");
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
_nprogress.default.configure({
  showSpinner: false
});
var whiteList = ['/login', '/auth-redirect', '/bind', '/register'];
_router.default.beforeEach(function (to, from, next) {
  _nprogress.default.start();
  if ((0, _auth.getToken)()) {
    /* has token*/
    if (to.path === '/login') {
      next({
        path: '/'
      });
      _nprogress.default.done();
    } else {
      if (_store.default.getters.roles.length === 0) {
        // 判断当前用户是否已拉取完user_info信息
        _store.default.dispatch('GetInfo').then(function (res) {
          // 拉取user_info
          var roles = res.data.role_id;
          // 测试 默认静态页面
          _store.default.dispatch('GenerateRoutes', {
            roles: roles
          }).then(function (accessRoutes) {
            // 根据roles权限生成可访问的路由表
            _router.default.addRoutes(accessRoutes); // 动态添加可访问路由表
            next(_objectSpread(_objectSpread({}, to), {}, {
              replace: true
            })); // hack方法 确保addRoutes已完成
          });
        }).catch(function (err) {
          _store.default.dispatch('FedLogOut').then(function () {
            _elementUi.Message.error(err);
            next({
              path: '/'
            });
          });
        });
      } else {
        next();
        // 没有动态改变权限的需求可直接next() 删除下方权限判断 ↓
        // if (hasPermission(store.getters.roles, to.meta.roles)) {
        //   next()
        // } else {
        //   next({ path: '/401', replace: true, query: { noGoBack: true }})
        // }
        // 可删 ↑
      }
    }
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next("/login?redirect=".concat(to.path)); // 否则全部重定向到登录页
      _nprogress.default.done();
    }
  }
});
_router.default.afterEach(function () {
  _nprogress.default.done();
});