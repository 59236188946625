"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/account-manage-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadView = exports.default = void 0;
require("core-js/modules/es6.function.name");
require("core-js/modules/web.dom.iterable");
var _router2 = require("@/router");
var _index = _interopRequireDefault(require("@/layout/index"));
var _system = _interopRequireDefault(require("@services/system"));
var _router = {
  'msg': '操作成功',
  'code': 200,
  'data': [{
    'path': '/platform_admin',
    'name': 'PlatformAdmin',
    'component': 'Layout',
    'meta': {
      'title': '业务平台管理',
      'icon': 'example'
    },
    'children': [{
      'name': 'Platform',
      'path': '/platform',
      'component': 'platform',
      'meta': {
        'title': '业务平台管理',
        'icon': 'example'
      }
    }]
  }, {
    'path': '/account_auth_admin',
    'name': 'AccountAuthAdmin',
    'component': 'Layout',
    'meta': {
      'title': '账户权限管理',
      'icon': 'example'
    },
    'children': [{
      'name': 'AccountAuth',
      'path': '/account_auth',
      'component': 'account-auth',
      'meta': {
        'title': '账户权限管理',
        'icon': 'example'
      },
      'hidden': true
    }]
  }, {
    'path': '/account_management_admin',
    'component': 'Layout',
    'name': 'AccountManagementAdmin',
    'meta': {
      'title': '账户管理',
      'icon': 'system'
    },
    'children': [{
      'name': 'AccountManagement',
      'path': '/account_management',
      'component': 'AccountManagement',
      'meta': {
        'title': '账户管理',
        'icon': 'system'
      }
    }]
  }, {
    'path': '/employee_department_admin',
    'component': 'Layout',
    'name': 'EmployeeAndDepartmentAdmin',
    'meta': {
      'title': '员工与部门管理',
      'icon': 'system'
    },
    'children': [{
      'name': 'EmployeeAndDepartment',
      'path': '/employee_department',
      'component': 'EmployeeAndDepartment',
      'meta': {
        'title': '员工与部门管理',
        'icon': 'system'
      }
    }]
  }, {
    'path': '/role_management_admin',
    'component': 'Layout',
    'name': 'RoleManagementAdmin',
    'meta': {
      'title': '角色管理',
      'icon': 'system'
    },
    'children': [{
      'name': 'RoleManagement',
      'path': '/role_management',
      'component': 'RoleManagement',
      'meta': {
        'title': '角色管理',
        'icon': 'system'
      }
    }]
  }, {
    'path': '/admin_configure_admin',
    'component': 'Layout',
    'name': 'AdminConfigureAdmin',
    'meta': {
      'title': '管理员配置',
      'icon': 'system'
    },
    'children': [{
      'name': 'AdminConfigure',
      'path': '/admin_configure',
      'component': 'AdminConfigure',
      'meta': {
        'title': '管理员配置',
        'icon': 'system'
      }
    }]
  }, {
    'path': '/system_log_admin',
    'component': 'Layout',
    'name': 'SystemLogAdmin',
    'meta': {
      'title': '系统日志',
      'icon': 'system'
    },
    'children': [{
      'name': 'SystemLog',
      'path': '/systemLog',
      'component': 'systemLog',
      'meta': {
        'title': '系统日志',
        'icon': 'system'
      }
    }]
  }]
};
var permission = {
  state: {
    routes: [],
    addRoutes: []
  },
  mutations: {
    SET_ROUTES: function SET_ROUTES(state, routes) {
      state.addRoutes = routes;
      state.routes = _router2.constantRoutes.concat(routes);
    }
  },
  actions: {
    // 生成路由
    GenerateRoutes: function GenerateRoutes(_ref) {
      var commit = _ref.commit;
      return new Promise(function (resolve) {
        // 抛出路由设置默认路由
        var objData = {
          pid: process.env.VUE_APP_PID
        };
        _system.default.loginRoles(objData).then(function (res) {
          if (res.data && res.data.length === 0) {
            var _accessedRoutes = filterAsyncRouter([]);
            _accessedRoutes.push({
              path: '*',
              redirect: '/404',
              hidden: true
            });
            commit('SET_ROUTES', _accessedRoutes);
            resolve(_router2.constantRoutes.concat(_accessedRoutes));
            return;
          }
          var menuData = res.data[0];
          var menuData_2 = [];
          var menuData_3 = [];
          var menuList_1 = JSON.parse(JSON.stringify(_router.data));
          // 第一次循环
          menuList_1.forEach(function (e) {
            menuData.map(function (item) {
              if (e.name === item.url) {
                menuData_2.push(e);
              }
            });
          });

          // 第二次循环
          menuData_2.map(function (item_2) {
            if (item_2.children.length > 0) {
              menuData_3 = [];
              item_2.children.map(function (item_3) {
                menuData.map(function (item_4) {
                  item_4.child.map(function (item_5) {
                    if (item_3.name === item_5.url) {
                      menuData_3.push(item_3);
                    }
                  });
                });
              });
              if (item_2.name === 'AccountAuthAdmin') {
                item_2.hidden = true;
              }
              item_2.children = menuData_3;
            }
          });
          // 添加动态路由
          var accessedRoutes = filterAsyncRouter(menuData_2);
          accessedRoutes.push({
            path: '*',
            redirect: '/404',
            hidden: true
          });
          commit('SET_ROUTES', accessedRoutes);
          resolve(_router2.constantRoutes.concat(accessedRoutes));
          return;
        });
        // 原始路由
        // const accessedRoutes = filterAsyncRouter(_router.data)
        // accessedRoutes.push({ path: '*', redirect: '/404', hidden: true })
        // commit('SET_ROUTES', accessedRoutes)
        // resolve(accessedRoutes)
        // return
        // 向后端请求路由数据
        // getRouters().then(res => {
        //   const accessedRoutes = filterAsyncRouter(res.data)
        //   accessedRoutes.push({ path: '*', redirect: '/404', hidden: true })
        //   commit('SET_ROUTES', accessedRoutes)
        //   resolve(accessedRoutes)
        // })
      });
    }
  }
};

// 遍历后台传来的路由字符串，转换为组件对象
function filterAsyncRouter(asyncRouterMap) {
  return asyncRouterMap.filter(function (route) {
    if (route.component) {
      // Layout组件特殊处理
      if (route.component === 'Layout') {
        route.component = _index.default;
      } else {
        route.component = loadView(route.component);
      }
    }
    if (route.children != null && route.children && route.children.length) {
      route.children = filterAsyncRouter(route.children);
    }
    return true;
  });
}
var loadView = function loadView(view) {
  // 路由懒加载
  // return () => import(`@/views/${view}`)
  return function (resolve) {
    return require(["@/views/".concat(view)], resolve);
  };
};
exports.loadView = loadView;
var _default = permission;
exports.default = _default;