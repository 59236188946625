var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-editor-container" }, [
    _vm._v("\n  欢迎来到后台管理系统\n"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }