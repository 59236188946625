"use strict";

var _interopRequireDefault = require("/var/lib/jenkins/workspace/account-manage-admin/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es7.array.includes");
require("core-js/modules/es6.string.includes");
var _store = _interopRequireDefault(require("@/store"));
/**
 * 操作权限处理
 */
var _default = {
  inserted: function inserted(el, binding) {
    var value = binding.value;
    var all_permission = '*:*:*';
    var permissions = _store.default.getters && _store.default.getters.permissions;
    if (value && value instanceof Array && value.length > 0) {
      var permissionFlag = value;
      var hasPermissions = permissions.some(function (permission) {
        return all_permission === permission || permissionFlag.includes(permission);
      });
      if (!hasPermissions) {
        el.parentNode && el.parentNode.removeChild(el);
      }
    } else {
      throw new Error('请设置操作权限标签值');
    }
  }
};
exports.default = _default;