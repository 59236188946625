var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login" },
    [
      _c(
        "el-form",
        {
          ref: "loginForm",
          staticClass: "login-form",
          attrs: { model: _vm.loginForm, rules: _vm.loginRules },
        },
        [
          _c("h3", { staticClass: "title" }, [_vm._v("ManBetX后台管理系统")]),
          _c(
            "el-form-item",
            { attrs: { prop: "username" } },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    type: "text",
                    "auto-complete": "off",
                    placeholder: "账号",
                  },
                  model: {
                    value: _vm.loginForm.username,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.loginForm,
                        "username",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "loginForm.username",
                  },
                },
                [
                  _c("svg-icon", {
                    staticClass: "el-input__icon input-icon",
                    attrs: { slot: "prefix", "icon-class": "user" },
                    slot: "prefix",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { prop: "password" } },
            [
              _c(
                "el-input",
                {
                  attrs: {
                    type: "password",
                    "auto-complete": "off",
                    placeholder: "密码",
                  },
                  nativeOn: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleLogin($event)
                    },
                  },
                  model: {
                    value: _vm.loginForm.password,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.loginForm,
                        "password",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "loginForm.password",
                  },
                },
                [
                  _c("svg-icon", {
                    staticClass: "el-input__icon input-icon",
                    attrs: { slot: "prefix", "icon-class": "password" },
                    slot: "prefix",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "clearfix" },
            [
              _c(
                "el-checkbox",
                {
                  staticClass: "fl",
                  staticStyle: { margin: "0px 0px 25px 0px" },
                  model: {
                    value: _vm.loginForm.rememberMe,
                    callback: function ($$v) {
                      _vm.$set(_vm.loginForm, "rememberMe", $$v)
                    },
                    expression: "loginForm.rememberMe",
                  },
                },
                [_vm._v("\n        记住密码\n      ")]
              ),
              _c(
                "el-popover",
                {
                  staticClass: "fr",
                  attrs: {
                    placement: "top",
                    width: "160",
                    trigger: "click",
                    content: "请联系管理员：Jar",
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "pwd",
                      attrs: { slot: "reference" },
                      slot: "reference",
                    },
                    [_vm._v(" 忘记密码？ ")]
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "el-form-item",
            { staticStyle: { width: "100%" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    loading: _vm.loading,
                    size: "medium",
                    type: "primary",
                  },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.handleLogin($event)
                    },
                  },
                },
                [
                  !_vm.loading
                    ? _c("span", [_vm._v("登 录")])
                    : _c("span", [_vm._v("登 录 中...")]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "el-login-footer" }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "验证码",
            visible: _vm.codeVisible,
            "before-close": _vm.handleClose,
            width: "300px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.codeVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "qrcode-2fa" },
            [
              _vm.qrcode
                ? _c("div", [
                    _vm._v(
                      "\n        密钥：" + _vm._s(_vm.secret) + "\n      "
                    ),
                  ])
                : _vm._e(),
              _vm.qrcode
                ? _c("div", { domProps: { innerHTML: _vm._s(_vm.qrcode) } })
                : _vm._e(),
              _c("el-input", {
                attrs: { placeholder: "请输入Google Authenticator验证码" },
                model: {
                  value: _vm.code,
                  callback: function ($$v) {
                    _vm.code = $$v
                  },
                  expression: "code",
                },
              }),
              _c(
                "el-button",
                {
                  staticClass: "mt20",
                  staticStyle: { width: "100%" },
                  attrs: {
                    loading: _vm.loading,
                    size: "medium",
                    type: "primary",
                  },
                  nativeOn: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.login($event)
                    },
                  },
                },
                [
                  !_vm.loading
                    ? _c("span", [_vm._v("登 录")])
                    : _c("span", [_vm._v("登 录 中...")]),
                ]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }